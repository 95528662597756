import resource from "./resource";
import api from "./api";
import Service from "../services/Service";
const DEBUG = false;
const dataProvider = {
    getList: (resource, params) => {
        DEBUG && console.log("called : ", "getList", resource)
        return api[resource].getList(params).then(res => {
            DEBUG && console.log("getList : ", resource, params, res);
            return res;
        })
    },
    getOne: (resource, params) => {
        DEBUG && console.log("called : ", "getOne", resource)
        return api[resource].getOne(params).then(res => {
            DEBUG && console.log("getOne : ", resource, params, res);
            return res;
        })
    },
    getMany: (resource, params) => {
        DEBUG && console.log("called : ", "getMany", resource)
        return api[resource].getMany(params).then(res => {
            DEBUG && console.log("getMany : ", resource, params, res);
            return res;
        })
    },
    getManyReference: (resource, params) => {
        DEBUG && console.log("called : ", "getManyReference", resource)
        return api[resource].getManyReference(params).then(res => {
            DEBUG && console.log("getManyReference : ", resource, params, res);
            return res;
        })
    },
    create: (resource, params) => {
        DEBUG && console.log("called : ", "create", resource)
        return api[resource].create(params).then(res => {
            DEBUG && console.log("create : ", resource, params, res);
            return res;
        })
    },
    update: (resource, params) => {
        DEBUG && console.log("called : ", "update", resource)
        return api[resource].update(params).then(res => {
            DEBUG && console.log("update : ", resource, params, res);
            return res;
        })
    },
    updateMany: (resource, params) => {
        DEBUG && console.log("called : ", "updateMany", resource)
        return api[resource].updateMany(params).then(res => {
            DEBUG && console.log("updateMany : ", resource, params, res);
            return res;
        })
    },
    delete: (resource, params) => {
        DEBUG && console.log("called : ", "delete", resource)
        return api[resource].delete(params).then(res => {
            DEBUG && console.log("delete : ", resource, params, res);
            return res;
        })
    },
    deleteMany: (resource, params) => {
        DEBUG && console.log("called : ", "deleteMany", resource)
        return api[resource].deleteMany(params).then(res => {
            DEBUG && console.log("deleteMany : ", resource, params, res);
            return res;
        })
    },
    upload: params => Service.upload(params),
    getLoginCode: params => api[resource.user].getLoginCode(params),
    faqResetImpression: params => api[resource.faq].resetImpression(params),
    addType: params => api[resource.dietType].addType(params),
    delType:params =>  api[resource.dietType].delType(params),
    sendTestNotification:params => api[resource.notification].sendTest(params),
    sendNotification:params => api[resource.notification].send(params),
    sendTestSms:params => api[resource.sms].sendTest(params),
    sendSms:params => api[resource.sms].send(params),
    changePassword:params => api[resource.user].changePassword(params)
}

export default dataProvider;