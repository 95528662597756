import React from 'react';
import {
    BooleanField,
    ChipField,
    Datagrid,
    ImageField,
    List,
    ReferenceArrayField,
    ReferenceField,
    SingleFieldList,
    TextField,SelectField
} from "react-admin";
import resource from "../../config/resource";

const ExerciseInstructionList = (props) => {
    return (
        <List {...props} sort={{field:"createdAt",order:"DESC"}}>
            <Datagrid rowClick={"show"}>
                <ImageField source="image"/>
                <TextField source="title"/>
                <ReferenceField reference={resource.exercise} source={"exercise"}>
                    <TextField source={"name"}/>
                </ReferenceField>
                <ReferenceField reference={resource.exerciseInstructionCategory} source={"category"}>
                    <TextField source={"title"}/>
                </ReferenceField>
                <SelectField source={"subscriptionType"} choices={[
                    {id: "pro", name: "pro"},
                    {id: "regular", name: "regular"},
                ]}/>
                <ReferenceArrayField source={"tags"} reference={resource.exerciseInstructionTag}>
                    <SingleFieldList>
                        <ChipField source="name"/>
                    </SingleFieldList>
                </ReferenceArrayField>
                <TextField source="time"/>
                <BooleanField source={"isPremium"}/>
                <BooleanField source="deleted"/>
            </Datagrid>

        </List>
    );
};

export default ExerciseInstructionList;