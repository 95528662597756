import React, {useMemo, useState} from 'react';
import Table from "../../components/Table";
import {useSelector} from "react-redux";
import resource from "../../config/resource";
import {Switch} from "@material-ui/core";

const foodFactKeys = ["calorieAmount",
    "proteinAmount",
    "fatAmount",
    "carbohydrateAmount",
    "sugarAmount",
    "fiberAmount",
    "sodiumAmount",
    "potassiumAmount",
    "calciumAmount",
    "magnesiumAmount",
    "ironAmount",
    "cholesterolAmount",
    "phosphorAmount",
    "saturatedFatAmount",
    "polyunsaturatedFatAmount",
    "transFatAmount",
    "monounsaturatedFatAmount"
]

const RecipeTable = ({formValues}) => {
    const [table, setTable] = useState("overall");
    const onToggleTable = () => {
        setTable(table => table==='overall'?'foodFact':'overall')
    }
    let resources = useSelector(state => state.admin.resources);

    const ingredientRows = useMemo(() => {
        let rows = [];
        for(let ingredient of formValues.ingredients || []){
            if (![ingredient, ingredient?.food, ingredient?.unit, ingredient?.amount].includes(undefined))
            {
                const mainFood = resources[resource.food].data[ingredient.food]
                const cookedFood = resources[resource.food].data[ingredient.cooked]
                const food = cookedFood || mainFood;
                const ratio = (cookedFood && mainFood?.cookedFoods?.length>0? mainFood?.cookedFoods.filter((cookedFoodItem) => cookedFoodItem.food === cookedFood._id)?.[0].ratio: 100) / 100;                const unit = resources[resource.foodUnit].data[ingredient.unit]
                const amount = unit?.name === 'گرم' ? ingredient.amount * ratio : ingredient.amount * mainFood?.foodUnitRatioArray.filter(unitRatio => unitRatio.unitId === unit?.id)[0]?.ratio * ratio;
                rows.push ({
                    unit:unit?.name,
                    name: cookedFood?.name || food?.name,
                    unitAmount:ingredient.amount,
                    amount: amount,
                    ...food?.foodFact || {},
                })
            }
        }
        return rows;
    },[formValues.ingredients,resources])
    const foodRow = useMemo(() => {
        const food = resources[resource.food].data[formValues.food];
        return {
            name:food?.name || formValues.newFood,
            amount:formValues.weight,
            ...(food?.foodFact || {})
        }
    },[formValues.food,formValues.newFood,formValues.weight,resources]);
    const totalRow = useMemo(() => {
        const ingredientsSums = ingredientRows.reduce((total,row) => {
            for(let key of Object.keys(row)){
                if(total[key] <0 || row[key] <0){
                    total[key] = -1;
                    continue;
                }
                if(total[key] === undefined)
                    total[key]=0;
                total[key] += key==='amount' ? row[key] :row[key]*row.amount/100;
            }
            return total
        },{})
        return  {
            ...ingredientsSums,
            caloriePerGram:ingredientsSums.calorieAmount/formValues.weight,
            name: 'total',
        }
    },[ingredientRows,formValues.weight])

    const sanitizeValue = (value) => {
        if(value < 0)
            return -1;
        return (Number.isNaN(value) || value==null) ? 0 : value.toFixed(2);
    }
    const overallColumns = [
        {
            key: "name",
            label: "Name",
        },
        {
            key: "caloriePerGram",
            label: "Calorie/Amount(cal/gr)",
            render: (val,record) => val ? sanitizeValue(val) : sanitizeValue(record.calorieAmount / 100)
        },
        {
            key: "amount",
            label: "Amount",
            render: (value,record) => `${sanitizeValue(value)} گرم${record.unit && record.unit!=='گرم' ? ` (${record.unitAmount} ${record.unit})`:''}`
        },
        {
            key: "calorieAmount",
            label: "Total Calorie",
            render: (val, record) => record.name ==='total' ? sanitizeValue(val) : sanitizeValue((record.calorieAmount * record.amount) / 100)
        },
        {
            key: "proteinAmount",
            label: "Total Protein",
            render: (val, record) => record.name ==='total' ? sanitizeValue(val) : sanitizeValue((record.proteinAmount * record.amount) / 100)
        },
        {
            key: "carbohydrateAmount",
            label: "Total Carbohydrate",
            render: (val, record) => record.name ==='total' ? sanitizeValue(val) : sanitizeValue((record.carbohydrateAmount * record.amount) / 100)
        },
        {
            key: "fatAmount",
            label: "Total Fat",
            render: (val, record) => record.name ==='total' ? sanitizeValue(val) : sanitizeValue((record.fatAmount * record.amount) / 100)
        },
    ];
    const foodFactRows = Object.keys(totalRow).filter(key => foodFactKeys.includes(key)).map(key => {
        return {name:key,amount:(totalRow[key]*100)/formValues.weight}
    })
       const foodFactColumns =  [
        {
            key: "name",
            label: "Name",
        },
        {
            key: "amount",
            label: "Calorie/Amount",
            render:sanitizeValue
        },
    ]

    return (
        <>
            <Switch
                checked={table==='foodFact'}
                onChange={onToggleTable}
                color="primary"
                name="checkedB"
            />
            {table==='overall' ?  <Table columns={overallColumns} records={[
                foodRow,
                totalRow,
                ...ingredientRows,
            ]}/> :
                <Table columns={foodFactColumns} records={foodFactRows}/>
            }

        </>
    );
};

export default RecipeTable;