import React from 'react';
import {
    BooleanField,
    ImageField,
    ReferenceArrayField,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField
} from 'react-admin';
import resource from "../../config/resource";

const SpecialTypeShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ImageField source="image" label="Image"/>
                <BooleanField addLabel source="deleted" />
                <BooleanField addLabel source="completed" />
                <TextField source="name" />
                <TextField fullWidth={true} source={"description"}/>
                <ReferenceArrayField source="suitableTags" reference={resource.foodTag} >
                    <SingleFieldList linkType={false}>
                        <TextField className="packageFoods" source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField source="notSuitableTags" reference={resource.foodTag} >
                    <SingleFieldList linkType={false}>
                        <TextField className="packageFoods" source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

export default SpecialTypeShow;