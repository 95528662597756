import React from 'react';
import {TextInput, SimpleForm, BooleanInput} from 'react-admin';

const CategoryForm = (props) => {
    return (
        <SimpleForm {...props} redirect={"list"}>
            <BooleanInput source="deleted" label="deleted"/>
            <TextInput source={"name"}/>
        </SimpleForm>
    );
};

export default CategoryForm;