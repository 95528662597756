import React from 'react';
import {BooleanField, Datagrid, DateField, List, TextField} from 'react-admin';

const HatedFoodList = (props) => {
    return (
      <List
        {...props}
          filterDefaultValues={{ type: 'KARAFS' }}
      >
        <Datagrid rowClick="show">
          <TextField source="name" />
          <DateField source="updatedAt" locales={'fa-IR'} />
          <BooleanField addLabel source="deleted" />
        </Datagrid>
      </List>
    );
};

export default HatedFoodList;