import Service from "./Service";


class FoodService extends Service {
    transformRequest(req) {
        return {...req, similarNames: req.similarNames?.map(similarNameObj => similarNameObj.name),barCodes: req.barCodes?.map(barCodeObj => barCodeObj.name)}
    }

    transformResponse(res) {
        return {...res, similarNames: res.similarNames?.map(similarName => ({name: similarName})),barCodes:res.barCodes?.map(barCode => ({name: barCode}))};
    }
}

export default FoodService;