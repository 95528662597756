import React from 'react';
import {
    ArrayField, BooleanField, ChipField,
    Datagrid, DateField,
    ReferenceArrayField,
    ReferenceField,
    Show,
    SimpleShowLayout, SingleFieldList,
    TextField
} from 'react-admin';
import resource from "../../config/resource";
import TextArrayField from "../../components/fields/TextArrrayField";
const FoodShow = (props) => {
    return (
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="name" />
          <TextField source="primaryFoodUnit.name" label="Primary Food Unit" />
          <ReferenceArrayField source="categories" reference={resource.category}>
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ArrayField source="barCodes">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="similarNames">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="foodUnitRatioArray">
            <Datagrid>
              <ReferenceField source="unitId" reference={resource.foodUnit} label="Food Unit">
                <TextField source="name" />
              </ReferenceField>
              <TextField source="ratio" label="gr" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="cookedFoods">
            <Datagrid>
              <ReferenceField source="food" reference={resource.food} label="Cooked Food Name">
                <TextField source="name" />
              </ReferenceField>
              <TextField source="ratio" label="gr" />
            </Datagrid>
          </ArrayField>
          <ReferenceArrayField source="tags" reference={resource.foodTag}>
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField
            source="suitableDiseases"
            reference={resource.disease}
            label="suitable for diseases"
            filter={{ deleted:false,type: 'KARAFS' }}
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>

          <ReferenceArrayField
            source="notSuitableDiseases"
            reference={resource.disease}
            filter={{ deleted:false,type: 'KARAFS' }}
            label="not suitable for diseases"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>

          <ReferenceArrayField
            source="hatedFoods"
            reference={resource.hatedFood}
            filter={{ deleted:false,type: 'KARAFS' }}
            label="Hated Foods"
            linkType={false}
          >
            <SingleFieldList >
              <ChipField source="name"  />
            </SingleFieldList>
          </ReferenceArrayField>
          <TextArrayField source={'seasons'} />
          <BooleanField addLabel source="notSuitablePregnancy" label="not suitable for pregnancy" />
          <BooleanField
            addLabel
            source="notSuitableBreastfeeding"
            label="not suitable for breastfeeding"
          />
          <BooleanField addLabel source="deleted" label="deleted" />
          <DateField source={'lastScoreUpdatedAt'} label={'lastScoreUpdatedAt'} locales={'fa-IR'} />
        </SimpleShowLayout>
      </Show>
    );
};

export default FoodShow;