import React from 'react';
import {List,Datagrid,TextField,BooleanField} from 'react-admin';
const ExerciseTagCategoryList = (props) => {
    return (
        <List {...props} sort={{field:"createdAt",order:"DESC"}}>
            <Datagrid rowClick={"show"}>
                <TextField source={"name"}/>
                <TextField source={"type"}/>
                <BooleanField source={"deleted"}/>
            </Datagrid>
        </List>
    );
};

export default ExerciseTagCategoryList;