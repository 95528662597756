import React from 'react';
import {BooleanInput, Edit, ReferenceInput, required, SelectInput, SimpleForm, TextInput} from "react-admin";
import resource from "../../config/resource";

const ExerciseTagEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="show">
                <BooleanInput source="deleted" />
                <TextInput source="name" validate={[required()]}/>
                <ReferenceInput source={"category"} reference={resource.exerciseInstructionTagCategory} validate={[required()]}>
                    <SelectInput source="name"/>
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export default ExerciseTagEdit;