import React from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    Edit,
    FormDataConsumer,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import Upload from "../../components/inputs/Upload";
import resource from "../../config/resource";
import TagsInput from "./TagsInput";

const ExerciseInstructionEdit = (props) => {

    const validate = values => {
        const errors = {};
        if(values.isPremium && !values.subscriptionType)
            errors.subscriptionType = "اجباری"
        return errors;
    }
    return (
        <Edit {...props}>
            <SimpleForm redirect="show" validate={validate}>
                <BooleanInput source={"deleted"}/>
                <BooleanInput source={"isPremium"}/>
                <TextInput source="title" validate={[required()]}/>
                <TextInput
                    source="description"
                    fullWidth
                    multiline
                    validate={[required()]}
                />
                <ReferenceInput filterToQuery={text => ({name:text})} source={"exercise"} reference={resource.exercise} validate={[required()]}>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                <ReferenceInput source={"category"} reference={resource.exerciseInstructionCategory} validate={[required()]}>
                    <SelectInput optionText="title"/>
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData }) =>
                        formData.isPremium && (
                            <SelectInput source="subscriptionType" choices={[
                                {id: "pro", name: "pro"},
                                {id: "regular", name: "regular"},
                            ]}/>
                        )
                    }
                </FormDataConsumer>
                <TagsInput/>
                <Upload source={"image"} type={"image"} segment={"exerciseInstructionImage"} validate={[required()]}/>
                <Upload source={"video"} type={"video"} segment={"exerciseInstructionVideo"} validate={[required()]}/>
                <NumberInput source={"time"} validate={[required()]}/>
            </SimpleForm>
        </Edit>
    );
};

export default ExerciseInstructionEdit;