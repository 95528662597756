import {stringify} from 'querystring';
import {HttpError} from 'react-admin';
import axios from 'axios';

export const SERVER_URL = process.env.REACT_APP_BASEURL || "https://content.bentoapp.org/api/v2"
// export const SERVER_URL = "https://stage.content.karafsapp.com/api/v2"

export const handleResponse = ({headers, data}) => {
    const response = {
        ...data,
        data: data.data ? fixResponseIds(data.data) : {},
    }
    if (headers['content-range'])
        response.total = parseInt(headers['content-range']?.split('/').pop(), 10);
    return response;
};

function fixResponseIds(data) {
    let res;
    if (Array.isArray(data)) {
        res = data.map(fixId)
    } else if (typeof data === 'object') {
        res = fixId(data);
    }
    return res;

    function fixId(res) {
        return {...res, id: res._id}
    }
}

function stringifyParams(params) {
    let query = {};
    for (let key of Object.keys(params)) {
        if (!(['filter', 'sort', 'pagination'].includes(key))) {
            query[key] = params[key]
        }
    }
    if (params.filter)
        query.filter = JSON.stringify(params.filter)
    if (params.sort)
        query.sort = JSON.stringify([params.sort.field, params.sort.order]);
    if (params.pagination)
        query.range = JSON.stringify([(params.pagination.page - 1) * params.pagination.perPage, params.pagination.page * params.pagination.perPage - 1])
    return stringify(query);
}

export function httpClient({URL, method='GET', data, params,headers, onUploadProgress = Function.prototype}) {
    return axios({
        url:URL,
        method,
        params,
        data,
        baseURL: SERVER_URL,
        headers: headers || {
            Accept: 'application/json',
            'x-deviceType' : 'panel',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        paramsSerializer: stringifyParams,
        responseType: 'json',
        onUploadProgress,
    }).then(res => {
        return handleResponse(res)
    }).catch(err => Promise.reject(new HttpError(
        (err.response.data && err.response.data.message) || err.response.data.statusCode,
        err.response.data.statusCode,
        err.response.data
        ))
    )
}

