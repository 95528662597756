import React from 'react';
import {Edit} from 'react-admin';
import SpecialTypeForm from "./SpecialTypeForm";

const SpecialTypeEdit = (props) => {
    return (
        <Edit {...props}>
           <SpecialTypeForm/>
        </Edit>
    );
};

export default SpecialTypeEdit;