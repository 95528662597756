import React from 'react';
import {Create, NumberInput, required, SelectInput, SimpleForm, TextInput} from "react-admin";
import Upload from "../../components/inputs/Upload";

const ExerciseCategoryCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <TextInput source="title" validate={[required()]}/>
                <NumberInput source={"order"}/>
                <SelectInput source="type" choices={[
                    {id: "CLASS", name: "class"},
                    {id: "EXERCISE", name: "exercise"},
                ]} validate={[required()]}/>
                <Upload source={"image"} type={"image"} segment={"exerciseInstructionCategory"} validate={[required()]}/>
            </SimpleForm>
        </Create>
    );
};

export default ExerciseCategoryCreate;