import React from 'react';
import {Create} from 'react-admin';
import SpecialTypeForm from "./SpecialTypeForm";

const SpecialTypeCreate = (props) => {
    return (
        <Create {...props}>
            <SpecialTypeForm/>
        </Create>
    );
};

export default SpecialTypeCreate;