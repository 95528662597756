import React, {useState} from 'react';
import {CheckboxGroupInput, DateField, Edit, ReferenceArrayInput,useMutation, required, TabbedForm, TextField,PasswordInput,TopToolbar,ShowButton,Button} from 'react-admin';
import EditToolbar from "../../components/EditToolbar";
import resource from "../../config/resource";
import CustomFormTab from "../../components/form/CustomFormTab";
import {Grid,Dialog,DialogContent,DialogActions} from "@material-ui/core";
import usePermission from "../../components/permission/usePermission";
import CustomForm from "../../components/form/CustomForm";
import useCustomNotify from "../../components/useCustomNotify";

const UserEdit = (props) => {
    const hasPermission = usePermission();
    return (
        <Edit {...props} actions={<Toolbar/>}>
            <TabbedForm redirect="show" toolbar={<EditToolbar/>}>
                <CustomFormTab label={"اطلاعات کاربری"}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <TextField source="username" label="نام کاربری"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <DateField locales="fa-IR" source="createdAt" label="زمان ثبت نام"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <DateField
                                locales="fa-IR"
                                source="updatedAt"
                                label="زمان آخرین بروزرسانی"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {hasPermission('user.edit.roles') ? (
                                <ReferenceArrayInput validate={required()} source="roles" reference={resource.role}
                                                     label="نوع کاربر">
                                    <CheckboxGroupInput source="name"/>
                                </ReferenceArrayInput>
                            ) : null}
                        </Grid>
                    </Grid>
                </CustomFormTab>
            </TabbedForm>
        </Edit>
    );
};

const Toolbar = ({basePath,data}) => {
    const notify = useCustomNotify()
    const [open, setOpen] = useState(false);
    const [changePassword] = useMutation();
    const onChangePassword = ({password}) => {
        changePassword({
            type:"changePassword",
            payload: {
                data: {password},
                id:data._id
            }
        },{
            onSuccess:res => {
                notify(res);
                handleClose();
            },
            onFailure:notify
        })
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleOpen = () => {
        setOpen(true);
    }
    return (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
            <Button color={"primary"} label={"تغییر پسورد"} onClick={handleOpen}/>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <CustomForm toolbar={null} save={onChangePassword}>
                    {(_,form) => (
                        <>
                            <DialogContent>
                                <PasswordInput source={"password"} label={"پسورد"}/>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary" label={"انصراف"}/>
                                <Button onClick={form.submit} label={"تغییر"}/>
                            </DialogActions>
                        </>
                    )}
                </CustomForm>
            </Dialog>
        </TopToolbar>
    )
}

export default UserEdit;