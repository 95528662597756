import React from 'react';
import {BooleanInput, CheckboxGroupInput, ReferenceArrayInput, SimpleForm, TextInput} from 'react-admin';
import resource from "../../config/resource";
import Upload from "../../components/inputs/Upload";

const SpecialTypeForm = (props) => {
    return (
        <SimpleForm {...props} redirect="list">
            <BooleanInput source="deleted" />
            <BooleanInput source="completed" />
            <TextInput source="name" />
            <TextInput
                source="description"
                fullWidth
                multiline
            />
            <Upload segment={"specialType"} type={"image"} source={"image"}/>
            <ReferenceArrayInput source="suitableTags" reference={resource.foodTag} label="Suitable Tags" >
                <CheckboxGroupInput source="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput source="notSuitableTags" reference={resource.foodTag} label="Not Suitable Tags" >
                <CheckboxGroupInput source="name" />
            </ReferenceArrayInput>
        </SimpleForm>
    );
};

export default SpecialTypeForm;