import React from 'react';
import {Edit} from 'react-admin';
import CategoryForm from "./CategoryForm";

const CategoryEdit = (props) => {
    return (
        <Edit {...props}>
            <CategoryForm/>
        </Edit>
    );
};

export default CategoryEdit;