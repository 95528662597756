import React from 'react';
import {
    ArrayField,
    BooleanField,
    ChipField,
    Datagrid,
    DateField,
    ImageField,
    NumberField,
    ReferenceArrayField,
    ReferenceField,
    Show,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';
import resource from "../../config/resource";
import ConditionalField from "../../components/fields/ConditionalField";

const DietTypeShow = (props) => {
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label={"details"}>
                    <BooleanField addLabel={true} emptyText={"-"} source="completed" label="completed"/>
                    <ImageField source="image" label="Image"/>
                    <ImageField source="large-image" label="Large Image"/>
                    <TextField source="name"/>
                    <ReferenceField source="dietTypeCategory" reference={resource.dietTypeCategory}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <TextField source="type"/>
                    <TextField source="hourType"/>
                    <ConditionalField condition={record => record.type}>
                        <NumberField source="fasting"/>
                    </ConditionalField>
                    <NumberField source="days"/>
                    <TextField source="description" fullWidth/>
                    <DateField source="createdAt" label="Create Date" locales={"fa-IR"}/>
                    <DateField source="updatedAt" label="Last Update" locales={"fa-IR"}/>
                    <ReferenceArrayField
                        source="packageCalories"
                        reference={resource.packageCalorie}
                        label="Calorie Amount Steps"
                    >
                        <SingleFieldList>
                            <ChipField source="amount"/>
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceArrayField source={"allowedFoods"} reference={resource.food}>
                        <SingleFieldList>
                            <ChipField source="name"/>
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceArrayField source={"notAllowedFoods"} reference={resource.food}>
                        <SingleFieldList>
                            <ChipField source="name"/>
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <TextField source={"sideEffects"}/>
                    <TextField source={"benefits"}/>
                    <TextField source={"recommendations"}/>
                    <TextField source={"target"}/>
                    <TextField source={"warning"}/>
                </Tab>
                <Tab label={"meal ratio array"}>
                    <ArrayField source="mealRatioArray" label="">
                        <Datagrid>
                            <TextField source="type"/>
                            <ArrayField source="mealRatios">
                                <Datagrid>
                                    <TextField source="meal"/>
                                    <TextField source="meal_text"/>
                                    <TextField source="ratio" label="ratio(%)"/>
                                </Datagrid>
                            </ArrayField>
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default DietTypeShow;