import React from 'react';
import {Create, ReferenceInput, required, SelectInput, SimpleForm, TextInput} from "react-admin";
import resource from "../../config/resource";

const ExerciseTagCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <TextInput source="name" validate={[required()]}/>
                <ReferenceInput source={"category"} reference={resource.exerciseInstructionTagCategory} validate={[required()]}>
                    <SelectInput source="name"/>
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export default ExerciseTagCreate;