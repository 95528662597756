import React, {useState} from 'react';
import {Notification, useLogin} from 'react-admin';
import {Avatar, Button, Container, Paper, TextField, ThemeProvider, Typography} from "@material-ui/core";
import Logo from '../../assets/images/bento_logo.webp';

import useStyles from "./styles";
import {theme} from "../../config/theme";
import useCustomNotify from "../../components/useCustomNotify";

const Login = () => {

    const notify = useCustomNotify();
    const classes = useStyles();
    const login = useLogin();
    const [values, setValues] = useState({
        username: {
            value: '',
            error: ''
        },
        password: {
            value: '',
            error: ''
        }
    })

    function onSubmit(e) {
        e.preventDefault();
        login({
            username: values.username.value,
            password: values.password.value
        }).catch(err => notify(err.message, "error"));
    }

    function onChange(e) {
        setValues({...values, [e.target.name]: {value: e.target.value}})
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar} alt="Bento Logo" src={Logo}/>
                    <Typography component="h1" variant="h5">ورود</Typography>
                    <form className={classes.form} onSubmit={onSubmit}>
                        <TextField
                            variant="filled"
                            margin="normal"
                            required
                            error={!!values.username.error}
                            helperText={values.username.error}
                            fullWidth
                            id="username"
                            label="نام کاربری"
                            name="username"
                            autoFocus
                            onChange={onChange}
                        />
                        <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="رمز عبور"
                            type="password"
                            id="password"
                            onChange={onChange}
                        />
                        <Button
                            // disabled={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            ورود
                        </Button>
                    </form>
                </Paper><Notification/>
            </Container>
        </ThemeProvider>

    )
};

export default Login;