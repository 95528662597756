import React from 'react';
import {
    BooleanField,
    DateField,
    ImageField,
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    SingleFieldList, ChipField, ReferenceArrayField, FileField, SelectField, useGetList
} from "react-admin";
import resource from "../../config/resource";


const ExerciseInstructionShow = (props) => {
    const {data: tagCategories, ids: tagCategoryIds} = useGetList(resource.exerciseInstructionTagCategory);
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <BooleanField source="deleted"/>
                <TextField source="_id"/>
                <TextField source="title"/>
                <TextField source="description"/>
                <ReferenceField reference={resource.exercise} source={"exercise"}>
                    <TextField source={"name"}/>
                </ReferenceField>
                <ReferenceField reference={resource.exerciseInstructionCategory} source={"category"}>
                    <TextField source={"title"}/>
                </ReferenceField>
                <SelectField source={"subscriptionType"} choices={[
                    {id: "pro", name: "pro"},
                    {id: "regular", name: "regular"},
                ]}/>
                {
                    tagCategoryIds?.map(tagCategoryId => {
                        const tagCategory = tagCategories[tagCategoryId];
                        const label = tagCategory.name;
                        const filter = {category: tagCategoryId};
                        const reference = resource.exerciseInstructionTag;
                        const source = `tagCategories.${tagCategoryId}`;
                        return (
                            tagCategory.singleSelect ?
                                <ReferenceField label={label} filter={filter}
                                                reference={reference} source={source}>
                                    <ChipField source={"name"}/>
                                </ReferenceField>:
                            <ReferenceArrayField label={label} filter={filter}
                                                 reference={reference} source={source}>
                                <SingleFieldList>
                                    <ChipField source="name"/>
                                </SingleFieldList>
                            </ReferenceArrayField>
                        )}
                    )
                }
                <ImageField source="image"/>
                <FileField source={"video"} title={"video"}/>
                <BooleanField source={"isPremium"}/>
                <TextField source="time"/>
                <DateField source="createdAt" locales={"fa-IR"}/>
                <DateField source="updatedAt" locales={"fa-IR"}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default ExerciseInstructionShow;