const resource = {
    user:"user",
    dietType:"dietType",
    hourType: "hourType",
    dietTypeCategory:"dietTypeCategory",
    specialType:"specialType",
    recipeCategory:"recipeCats",
    foodTag:"foodTag",
    foodUnit:"foodUnit",
    hatedFood:"hatedFood",
    packageCalorie:"packageCalorie",
    mealCategory:"mealCategory",
    disease:"disease",
    package:"package",
    role:"role",
    food:"food",
    warining: "warning",
    recipe:"recipe",
    groceryCategory:"groceryCategory",
    category:"category",
    exerciseInstructionTagCategory:"exerciseInstructionTagCategory",
    exerciseInstructionTag:"exerciseInstructionTag",
    exerciseInstructionCategory:"exerciseInstructionCategory",
    exerciseInstruction:"exerciseInstruction",
    exercise:"exercise",
    tip:'tip'

}
export default resource;