import React from 'react';
import {List,Datagrid,TextField,BooleanField,DateField} from 'react-admin';

const CategoryList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick={"edit"}>
                <TextField source={"name"}/>
                <DateField source={"createdAt"} locales={"fa-IR"}/>
                <BooleanField source={"deleted"}/>
            </Datagrid>
        </List>
    );
};

export default CategoryList;