import React from 'react';
import {BooleanField, Datagrid, ImageField, List, NumberInput, TextField} from "react-admin";

const ExerciseCategoryList = (props) => {
    return (
        <List {...props} sort={{field:"createdAt",order:"DESC"}}>
            <Datagrid rowClick={"show"}>
                <ImageField source="image"/>
                <TextField source="title"/>
                <TextField source={"order"}/>
                <TextField source="type"/>
                <BooleanField source="deleted"/>
            </Datagrid>
        </List>
    );
};

export default ExerciseCategoryList;