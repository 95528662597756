import Service from "./Service";

class ExerciseInstructionService extends Service {

    getOne(params) {

        return super.getOne(params).then(async res => {
            const result = {
                ...res,
                data:{
                    ...res.data,
                    tagCategories:{}
                }
            };
            res.data.tagCategories.forEach(tagCategory => {
                console.log(tagCategory)
                result.data.tagCategories[tagCategory._id] = tagCategory.singleSelect ? tagCategory.tags[0] : tagCategory.tags;
            })
            return result;
        });
    }

    transformRequest(req) {
        const result= {...req,tags:[]}
        for(let tagCategoryId in req.tagCategories){
            if(typeof req.tagCategories[tagCategoryId] === 'string')
                result.tags.push(req.tagCategories[tagCategoryId]);
            else{
                result.tags.push(...req.tagCategories[tagCategoryId])
            }

        }
        return result;
    }

    transformResponse(res) {
        return res;
    }
}

export default ExerciseInstructionService;