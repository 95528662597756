import React from 'react';
import {Create} from 'react-admin';
import CategoryForm from "./CategoryForm";

const CategoryCreate = (props) => {
    return (
        <Create {...props}>
            <CategoryForm/>
        </Create>
    );
};

export default CategoryCreate;