import React from 'react';
import {
    Edit,
    SimpleForm,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    SelectArrayInput,
    TextInput,
    required,
    BooleanInput
} from 'react-admin';
import resource from "../../config/resource";
const TipEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <BooleanInput source={"deleted"}/>
                <TextInput source={"text"} label={"متن"} validate={[required()]} fullWidth/>
                <ReferenceArrayInput reference={resource.disease} source={"diseases"} label={"بیماری ها"}>
                    <AutocompleteArrayInput optionText={"name"}/>
                </ReferenceArrayInput>
                <ReferenceArrayInput reference={resource.specialType} source={"specialTypes"} label={"نوع رژیم"}>
                    <AutocompleteArrayInput optionText={"name"}/>
                </ReferenceArrayInput>
                <ReferenceArrayInput reference={resource.dietType} source={"dietTypes"} label={"نوع برنامه غذایی"}>
                    <AutocompleteArrayInput optionText={"name"}/>
                </ReferenceArrayInput>
                <SelectArrayInput label={"هدف"} source={"weightGoal"} choices={[{id:'weightGain',name:'weightGain'},{id:'weightLoss',name:'weightLoss'},{id:'weightFix',name:'weightFix'},{id:'pregnancy',name:'pregnancy'},{id:'breastfeeding',name:'breastfeeding'}]}/>
                <SelectArrayInput label={"روش برنامه"} source={"goalMethod"} choices={[{id:'diet',name:'diet'},{id:'calorieMeter',name:'calorieMeter'}]}/>
            </SimpleForm>
        </Edit>
    );
};

export default TipEdit;