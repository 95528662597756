import React from 'react';
import {
    Datagrid,
    DateField,
    Filter,
    List,
    TextField,
    TextInput,
    SelectArrayInput,
    ReferenceArrayInput, AutocompleteArrayInput
} from 'react-admin';
import resource from "../../config/resource";

const TipList = (props) => {
    return (
        <List {...props} filters={<TipFilter/>} sort={{field:"createdAt",order:'DESC'}}>
            <Datagrid rowClick={"show"}>
                <TextField source={"text"}/>
                <DateField source={"createdAt"} locales={"fa-IR"}/>
                <DateField source={"updatedAt"} locales={"fa-IR"}/>
            </Datagrid>
        </List>
    );
};

const TipFilter = (props) => (
    <Filter {...props}>
        <TextInput source={"text"} alwaysOn/>
        <SelectArrayInput label={"هدف"} source={"weightGoal"} alwaysOn
                          choices={[{id: 'weightGain', name: 'weightGain'}, {
                              id: 'weightLoss',
                              name: 'weightLoss'
                          }, {id: 'weightFix', name: 'weightFix'}, {
                              id: 'pregnancy',
                              name: 'pregnancy'
                          }, {id: 'breastfeeding', name: 'breastfeeding'}]}/>
        <SelectArrayInput alwaysOn label={"روش برنامه"} source={"goalMethod"} choices={[{id:'diet',name:'diet'},{id:'calorieMeter',name:'calorieMeter'}]}/>
        <ReferenceArrayInput alwaysOn reference={resource.disease} source={"diseases"} label={"بیماری ها"}>
            <AutocompleteArrayInput optionText={"name"}/>
        </ReferenceArrayInput>
        <ReferenceArrayInput alwaysOn reference={resource.specialType} source={"specialTypes"} label={"نوع رژیم"}>
            <AutocompleteArrayInput optionText={"name"}/>
        </ReferenceArrayInput>
        <ReferenceArrayInput alwaysOn reference={resource.dietType} source={"dietTypes"} label={"نوع برنامه غذایی"}>
            <AutocompleteArrayInput optionText={"name"}/>
        </ReferenceArrayInput>
    </Filter>
);

export default TipList;