import resource from "./resource";
import api from "./api";

const authProvider = {
    login: async ({username, password}) => {
        return api[resource.user].login({username, password}).then(({data}) => {
            localStorage.setItem('token', data.token)
            localStorage.setItem('refreshToken', data.refreshToken)
            localStorage.setItem('loginDate', new Date().toJSON())
            localStorage.setItem('user', JSON.stringify(data.user))
            localStorage.setItem('roles', data.user.roles)
        })
    },
    checkError: error => {
        if(error.message==="token expired"){
            return api[resource.user].refreshToken().then(({data, success}) => {
                if(success){
                    localStorage.removeItem("token");
                    localStorage.setItem('token', data.token)
                    localStorage.removeItem("refreshToken");
                    localStorage.setItem('refreshToken', data.refreshToken)
                    return Promise.resolve();
                }
                return Promise.reject();
            })
        }
        return Promise.resolve()
    },
    checkAuth: params => {
        return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("roles");
        localStorage.removeItem("user");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("loginDate");
        return Promise.resolve();
    },
    getIdentity: () => {
        return Promise.resolve();
        // return api[resource.user].getCurrentUser().then(user => {
        //     localStorage.setItem('user', JSON.stringify(user))
        //     localStorage.setItem('roles', user.roles)
        // })
    },
    getPermissions: params => {
        const userRoles = localStorage.getItem("roles")?.split(',');
        return userRoles ? Promise.resolve(userRoles) : Promise.reject();
    },
}
export default authProvider;