import React from 'react';
import {
    ChipField,
    DateField,
    ReferenceArrayField,
    Show,
    SingleFieldList,
    TabbedShowLayout,
    TextField,
} from 'react-admin'
import resource from "../../config/resource";
import CustomTab from "../../components/Layout/CustomTab";
import {Grid} from "@material-ui/core";

const UserShow = (props) => {
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <CustomTab label={"اطلاعات کاربری"}>
                    <Grid container>
                        <TextField source="username" label={"نام کاربری"}/>
                        <DateField locales="fa-IR" source="createdAt" label="زمان ثبت نام"/>
                        <DateField
                            locales="fa-IR"
                            source="updatedAt"
                            label="زمان آخرین بروزرسانی"
                        />
                        <Grid item xs={12}>
                            <ReferenceArrayField source="roles" reference={resource.role} label={'نوع کاربر'}>
                                <SingleFieldList linkType={false}>
                                    <ChipField source="name"/>
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </Grid>
                    </Grid>
                </CustomTab>
            </TabbedShowLayout>
        </Show>
    );
};

export default UserShow;