import React from 'react';
import {
    CheckboxGroupInput,
    Create,
    PasswordInput,
    ReferenceArrayInput,
    required,
    SimpleForm,
    TextInput
} from 'react-admin';
import resource from "../../config/resource";

const UserCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <TextInput source="username" label="نام کاربری" validate={[required()]} />
                <PasswordInput source={"password"} label={"پسورد"}/>
                <ReferenceArrayInput validate={required()} source="roles" reference={resource.role} label="نوع کاربر">
                    <CheckboxGroupInput source="name" />
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    );
};

export default UserCreate;