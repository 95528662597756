import React from 'react';
import {SelectInput, SimpleForm, TextInput, Edit, BooleanInput, required, NumberInput} from "react-admin";
import Upload from "../../components/inputs/Upload";

const ExerciseCategoryEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="show">
                <BooleanInput source={"deleted"}/>
                <TextInput source="title" validate={[required()]}/>
                <NumberInput source={"order"}/>
                <SelectInput source="type" choices={[
                    {id: "CLASS", name: "class"},
                    {id: "EXERCISE", name: "exercise"},
                ]} validate={[required()]}/>
                <Upload source={"image"} type={"image"} segment={"exerciseInstructionCategory"} validate={[required()]}/>
            </SimpleForm>
        </Edit>
    );
};

export default ExerciseCategoryEdit;