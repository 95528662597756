import React from 'react';
import {Show, TextField,SimpleShowLayout} from 'react-admin';

function CategoryShow(props) {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source={"name"}/>
            </SimpleShowLayout>
        </Show>
    );
}

export default CategoryShow;