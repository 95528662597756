import resource from "./resource";
import UserService from "../services/user";
import Service from "../services/Service";
import DietTypeService from "../services/dietType";
import FoodService from "../services/food";
import PackageService from "../services/package";
import ExerciseInstructionService from "../services/exerciseInstruction";

const api = {
    [resource.user]:new UserService("/user"),
    [resource.role]:new Service("/role"),
    [resource.dietType]:new DietTypeService("/dietType"),
    [resource.dietTypeCategory]:new Service("/dietTypeCategory"),
    [resource.packageCalorie]:new Service("/package/packageCalorie"),
    [resource.package]:new PackageService("/package"),
    [resource.specialType]: new Service("/food/specialType"),
    [resource.recipeCategory]:new Service("/food/category"),
    [resource.foodTag]:new Service("/food/tag"),
    [resource.foodUnit]:new Service("/food/foodUnit"),
    [resource.hatedFood]: new Service("/food/hatedFood"),
    [resource.mealCategory]:new Service("/package/category"),
    [resource.disease]:new Service("/food/disease"),
    [resource.food]:new FoodService("/food"),
    [resource.recipe]:new Service("/recipe"),
    [resource.groceryCategory]:new Service("/grocerycategory"),
    [resource.category]:new Service("/category"),
    [resource.exerciseInstructionTagCategory]:new Service("/exerciseInstructionTag/category"),
    [resource.exerciseInstructionTag]: new Service("/exerciseInstructionTag"),
    [resource.exerciseInstructionCategory] : new Service("/exerciseInstruction/category"),
    [resource.exerciseInstruction]: new ExerciseInstructionService("/exerciseInstruction"),
    [resource.exercise]: new Service("/exercise"),
    [resource.tip]: new Service("/tip"),

}
export default api;