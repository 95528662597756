import React from 'react';
import {BooleanField, DateField, ReferenceField, Show, SimpleShowLayout, TextField} from "react-admin";
import resource from "../../config/resource";

const ExerciseTagShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <BooleanField source="deleted"/>
                <TextField source="_id"/>
                <TextField source="name"/>
                <ReferenceField source="category" reference={resource.exerciseInstructionTagCategory}>
                    <TextField source="name"/>
                </ReferenceField>
                <DateField source="createdAt" locales={"fa-IR"}/>
                <DateField source="updatedAt" locales={"fa-IR"}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default ExerciseTagShow;