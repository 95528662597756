import React from 'react';
import {List,BooleanField, Datagrid, ReferenceField, TextField} from "react-admin";
import resource from "../../config/resource";

const ExerciseTagList = (props) => {
    return (
        <List {...props} sort={{field:"createdAt",order:"DESC"}}>
            <Datagrid rowClick={"show"}>
                <TextField source="name"/>
                <ReferenceField source="category" reference={resource.exerciseInstructionTagCategory}>
                    <TextField source="name"/>
                </ReferenceField>
                <BooleanField source="deleted"/>
            </Datagrid>
        </List>
    );
};

export default ExerciseTagList;