import React from 'react';
import {BooleanField, TextField,DateField,SimpleShowLayout,Show} from "react-admin";

const ExerciseTagCategoryShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <BooleanField source={"deleted"}/>
                <BooleanField source={"singleSelect"}/>
                <TextField source={"_id"}/>
                <TextField source={"name"}/>
                <TextField source={"type"}/>
                <DateField source="createdAt" locales={"fa-IR"}/>
                <DateField source="updatedAt" locales={"fa-IR"}/>

            </SimpleShowLayout>
        </Show>
    );
};

export default ExerciseTagCategoryShow;