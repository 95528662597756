import resource from "./resource";

const USER_ROLE = 'user';
const ADMIN_ROLE = 'admin';
const RECIPE_ADMIN_ROLE = 'recipe-admin';
const NUTRITIONIST_ROLE = 'nutritionist';

export const permissions = {
    [resource.user]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE],
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE],
        },
        edit: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE],
        },
        create: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        }
    },
    [resource.diet]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
    },
    [resource.dietType]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
    },
    [resource.dietTypeCategory]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
    },
    [resource.specialType]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
    },
    [resource.recipeCategory]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [RECIPE_ADMIN_ROLE]
        },
    },
    [resource.recipe]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [RECIPE_ADMIN_ROLE]
        },
    },
    [resource.foodTag]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
    },
    [resource.food]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE],
        },
        create: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
    },
    [resource.package]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
    },
    [resource.foodUnit]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
    },
    [resource.hatedFood]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
    },
    [resource.packageCalorie]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
    },
    [resource.mealCategory]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
    },
    [resource.disease]: {
        list: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [USER_ROLE,ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, RECIPE_ADMIN_ROLE]
        },
    },
    [resource.groceryCategory]: {
        list: {
            base: [RECIPE_ADMIN_ROLE]
        },
        show: {
            base: [RECIPE_ADMIN_ROLE]
        },
        edit: {
            base: [RECIPE_ADMIN_ROLE]
        },
        create: {
            base: [RECIPE_ADMIN_ROLE]
        },
    },
    [resource.exerciseInstruction]: {
        list: {
            base: [ADMIN_ROLE]
        },
        show: {
            base: [ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE]
        },
    },
    [resource.exerciseInstructionCategory]: {
        list: {
            base: [ADMIN_ROLE]
        },
        show: {
            base: [ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE]
        },
    },
    [resource.exerciseInstructionTag]: {
        list: {
            base: [ADMIN_ROLE]
        },
        show: {
            base: [ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE]
        },
    },
    [resource.exerciseInstructionTagCategory]: {
        list: {
            base: [ADMIN_ROLE]
        },
        show: {
            base: [ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE]
        },
    },
    [resource.tip]:{
        list: {
            base: [NUTRITIONIST_ROLE]
        },
        show: {
            base: [NUTRITIONIST_ROLE]
        },
        edit: {
            base: [NUTRITIONIST_ROLE]
        },
        create: {
            base: [NUTRITIONIST_ROLE]
        },
    }
}
