import React from 'react';
import {Create, required, SelectInput, SimpleForm, TextInput,BooleanInput} from "react-admin";

const ExerciseTagCategoryCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <BooleanInput source={"singleSelect"}/>
                <TextInput source="name" validate={[required()]}/>
                <SelectInput defaultValue={"none"} source="type" choices={[
                    {id: "none", name: "none"},
                    {id: "severity", name: "severity"},
                ]} validate={[required()]}/>
            </SimpleForm>
        </Create>
    );
};

export default ExerciseTagCategoryCreate;