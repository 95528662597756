import React from 'react';
import {BooleanField, DateField, ImageField, Show, SimpleShowLayout, TextField} from "react-admin";

const ExerciseCategoryShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <BooleanField source="deleted"/>
                <TextField source="_id"/>
                <TextField source="title"/>
                <TextField source="order"/>
                <TextField source="type"/>
                <ImageField source="image"/>
                <DateField source="createdAt" locales={"fa-IR"}/>
                <DateField source="updatedAt" locales={"fa-IR"}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default ExerciseCategoryShow;