import React from 'react';
import {ReferenceArrayInput, SelectArrayInput, useGetList,ReferenceInput,SelectInput} from "react-admin";
import resource from "../../config/resource";

const TagsInput = () => {

    const {data: tagCategories, ids: tagCategoryIds} = useGetList(resource.exerciseInstructionTagCategory);

    return (
        tagCategoryIds?.map(tagCategoryId => {
            const tagCategory = tagCategories[tagCategoryId];
            const label = tagCategory.name;
            const filter = {category: tagCategoryId};
            const reference = resource.exerciseInstructionTag;
            const source = `tagCategories.${tagCategoryId}`;
                return (
                    tagCategory.singleSelect ?
                        <ReferenceInput label={label} filter={filter} reference={reference} source={source}>
                            <SelectInput optionText={"name"}/>
                        </ReferenceInput> :
                        <ReferenceArrayInput label={label} filter={filter} reference={reference} source={source}>
                        <SelectArrayInput optionText={"name"}/>
                    </ReferenceArrayInput>
                )
            }
        )
    );
};

export default TagsInput;
