import React from 'react';
import {BooleanInput, Edit, required, SelectInput, SimpleForm, TextInput} from "react-admin";

const ExerciseTagCategoryEdit = (props) => {
    return (
        <Edit {...props} >
            <SimpleForm redirect="show">
                <BooleanInput source="deleted" />
                <BooleanInput source={"singleSelect"}/>
                <TextInput source="name" validate={[required()]}/>
                <SelectInput defaultValue={"none"} source="type" choices={[
                    {id: "none", name: "none"},
                    {id: "severity", name: "severity"},
                ]} validate={[required()]}/>
            </SimpleForm>
        </Edit>
    );
};

export default ExerciseTagCategoryEdit;